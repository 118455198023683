import axios from 'axios';

export const GET = url => {
  if (url) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response);
          });
      } catch (err) {
        reject(err.message);
      }
    });
  }
};

export const POST = (url, payload, config) => {
  if (url) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url, payload, config)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err.response);
          });
      } catch (err) {
        reject(err.message);
      }
    });
  }
};
