import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { ImLinkedin } from 'react-icons/im';
import {
  BsInstagram,
  BsDribbble,
  BsBehance,
  BsFacebook,
  BsMedium,
} from 'react-icons/bs';

import Layout from '../components/Layout';
import Seo from '../components/SEO';

import Section from '../components/Section';
import Container from '../components/Container';

import * as API from '../services';
import { CONTACT } from '../services/api';

const SocialMedia = [
  {
    title: 'Instagram',
    icon: <BsInstagram />,
    url: 'https://instagram.com/hiehq',
  },
  {
    title: 'Behance',
    icon: <BsBehance />,
    url: 'https://www.behance.net/hiestudiohq',
  },
  {
    title: 'Dribbble',
    icon: <BsDribbble />,
    url: 'https://dribbble.com/hiehq',
  },
  {
    title: 'LinkedIn',
    icon: <ImLinkedin />,
    url: 'https://www.linkedin.com/company/hie-hq',
  },
  {
    title: 'Facebook',
    icon: <BsFacebook />,
    url: 'https://www.facebook.com/hiehq',
  },
  {
    title: 'Medium',
    icon: <BsMedium />,
    url: 'https://medium.com/@hiehq',
  },
];

const contactUsRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string(),
    country: Yup.string(),
    message: Yup.string().required(),
  }).required(),
);

const ContactUsPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "contact-us-page-banner.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: contactUsRules,
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      country: '',
      message: '',
    },
  });
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const imageData = data.desktop.childImageSharp.fluid;

  const sources = [
    { aspectRatio: 1, src: '', srcSet: '' },
    { ...imageData, media: '(min-width: 1024px)' },
  ];

  const onSubmit = async data => {
    try {
      setLoading(true);
      await API.POST(CONTACT, data);
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout pageId="contact-us" location={location} footer={false}>
      <Seo title="Contact Us" />

      <Section id="hero" backgroundImage={sources}>
        <Container className="mb-36 lg:mb-24 xl:mb-0">
          <span className="flex-1 flex items-center justify-center w-full mt-20">
            <div className="flex-1 flex flex-col items-start justify-center">
              <h1
                className="ff-tertiary fs-46 fs-lg-48 fw-500 m-0"
                style={{ maxWidth: '500px' }}
              >
                <span className="text-primary fw-700">Your</span> Product{' '}
                <br className="hidden lg:block" />
                <span className="text-primary fw-700">Journey</span> Starts
                Here!
              </h1>
            </div>
          </span>
        </Container>
      </Section>
      <Section id="form" backgroundColor="#ffffff" overflow="visible">
        <Container>
          <div className="flex flex-col xl:grid xl:grid-cols-2 gap-6 w-full">
            <div className="flex flex-col mt-12 xl:mt-0">
              <span className="flex flex-col xl:flex-row">
                <span className="flex flex-col">
                  <span className="fs-16 text-gray-2">For work inquiry</span>
                  <a
                    className="fs-24 text-gray-3 mt-3"
                    href="mailto:hi@hiehq.com"
                  >
                    hi@hiehq.com
                  </a>
                </span>
                <span className="flex flex-col xl:ml-28 mt-12 xl:mt-0">
                  <span className="fs-16 text-gray-2">For career inquiry</span>
                  <a
                    className="fs-24 text-gray-3 mt-3"
                    href="mailto:hr@hiehq.com"
                  >
                    hr@hiehq.com
                  </a>
                </span>
              </span>

              <span className="flex flex-col mt-12 lg:mt-20">
                <span className="text-gray-2">Follow us:</span>
                <span className="mt-4">
                  <ul className="flex flex-wrap justify-start social-medias">
                    {SocialMedia.map((item, i) => {
                      return (
                        <li key={`social-media-${i}`} className="mr-6 mb-4">
                          <a
                            href={item.url}
                            className={`fs-24 inline-block text-gray-3`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {item.icon}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </span>
              </span>
            </div>

            <div className="order-first xl:order-last">
              <div className="relative py-6 lg:py-8 px-6 lg:px-8 ml-auto mr-auto xl:mr-0 contact__form">
                <h6 className="fs-24 fw-700 text-black mb-4">
                  Fill the form below!
                </h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="w-full flex flex-col">
                    <div className="form__group field pb-4">
                      <input
                        type="text"
                        id="name"
                        className="form__field"
                        placeholder="Name"
                        autoComplete="off"
                        {...register('name')}
                      />
                      <label htmlFor="name" className="form__label">
                        Name
                      </label>
                      <span className="error">{errors.name?.message}</span>
                    </div>

                    <div className="form__group field pb-4">
                      <input
                        type="email"
                        id="email"
                        className="form__field"
                        placeholder="Email"
                        autoComplete="off"
                        {...register('email')}
                      />
                      <label htmlFor="email" className="form__label">
                        Email
                      </label>
                      <span className="error">{errors.email?.message}</span>
                    </div>

                    <div className="form__group field pb-4">
                      <input
                        type="tel"
                        id="tel"
                        className="form__field"
                        placeholder="Mobile Number"
                        autoComplete="off"
                        {...register('phone')}
                      />
                      <label htmlFor="tel" className="form__label">
                        Mobile Number
                      </label>
                      <span className="error">{errors.phone?.message}</span>
                    </div>

                    <div className="form__group field pb-4">
                      <input
                        type="text"
                        id="country"
                        className="form__field"
                        placeholder="Country"
                        autoComplete="off"
                        {...register('country')}
                      />
                      <label htmlFor="country" className="form__label">
                        Country
                      </label>
                      <span className="error">{errors.country?.message}</span>
                    </div>

                    <div className="form__group field pb-4">
                      <input
                        type="text"
                        id="message"
                        className="form__field"
                        placeholder="Message"
                        autoComplete="off"
                        {...register('message')}
                      />
                      <label htmlFor="message" className="form__label">
                        Message
                      </label>
                      <span className="error">{errors.message?.message}</span>
                    </div>

                    <div className="flex justify-end mt-4">
                      {!submitted ? (
                        <button
                          type="submit"
                          className="btn btn-sm fw-400 block px-8 text-primary"
                          disabled={loading}
                        >
                          Send
                        </button>
                      ) : (
                        // eslint-disable-next-line react/no-unescaped-entities
                        <span className="text-primary">
                          Thank you for contacting us, we'll get back to you
                          soon!
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>

        <div className="container px-8 mx-auto pt-8 pb-12 flex flex-col xl:flex-row items-center xl:justify-start">
          <span className="mb-4 xl:mb-0 xl:ml-4 xl:order-last">
            <a className={`btn-link btn-dark fs-16`} href="/sitemap.xml">
              Sitemap
            </a>
          </span>
          <span className="text-black">
            {new Date().getFullYear()}, HIEHQ All rights reserved
          </span>
        </div>
      </Section>
    </Layout>
  );
};

ContactUsPage.propTypes = {
  location: PropTypes.object,
};

export default ContactUsPage;
